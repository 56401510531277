<template>
  <div class="">
    <v-layout row wrap justify-space-around class="mt-7">
      <v-flex class="xs12 sm6 md6 lg6 white elevation-2 rounded">
        <v-layout row wrap justify-end>
          <v-spacer></v-spacer>
          <v-flex xs7 sm5 md5 lg5 class="mt-3 pr-3 text-right">
            <v-menu offset-y v-model="menuLang" class="">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" style="cursor: pointer">
                  <flag :iso="$i18n.locale == 'en' ? 'us' : $i18n.locale" />
                  <span class="ml-2 subtitle-2">{{
                    $i18n.locale.toUpperCase()
                  }}</span>
                </v-btn>
              </template>
              <v-list dense max-height="300" class="overflow-y-auto pa-0" tile>
                <div v-for="item in languages" :key="item.lang">
                  <v-list-item @click="switchLanguage(item.lang)" class="">
                    <v-list-item-icon>
                      <flag :iso="item.flag" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        {{ item.lang.toUpperCase() }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="my-4 px-2">
          <v-flex xs12 sm12 md12 lg12 class="text-center">
            <p class="font-weight-bold text-h6">
              {{ title }}
            </p>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
        <form ref="form">
          <span class="px-4 font-weight-medium text-capitalize body-2"
            >{{ $t("new password") }}
          </span>
          <v-text-field
            outlined
            dense
            v-model="password"
            @keyup.enter="validate"
            @click:append="viewPass = !viewPass"
            :append-icon="viewPass ? 'mdi-eye-off' : 'mdi-eye'"
            :type="viewPass ? 'text' : 'password'"
            :rules="rules.password"
            class="px-4"
          ></v-text-field>
          <span class="px-4 font-weight-medium text-capitalize body-2"
            >{{ $t("confirm password") }}
          </span>
          <v-text-field
            outlined
            dense
            v-model="confirmPassword"
            @keyup.enter="validate"
            @click:append="viewPassConfirm = !viewPassConfirm"
            :append-icon="viewPassConfirm ? 'mdi-eye-off' : 'mdi-eye'"
            :type="viewPassConfirm ? 'text' : 'password'"
            :rules="rules.confirmPassword"
            class="px-4"
          ></v-text-field>
          <!-- login row -->
          <v-layout row wrap class="px-7">
            <v-spacer></v-spacer>
            <v-flex xs12 sm6 md6 lg6 class="text-right">
              <p
                class="blue--text mt-4 caption mr-1"
                style="text-decoration: underline"
                @click="loginPage()"
              >
                {{ $t("login") }}
              </p>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mb-2 px-7">
            <v-flex xs12 sm12 md12 lg12>
              <v-btn
                :color="$store.state.secondaryColor"
                dark
                block
                @click="validate()"
                :loading="loading"
              >
                {{ $t("submit") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </form>

        <!-- <h1 class="text-center  mt-2 mb-4 font-weight-light">Login</h1> -->

        <v-snackbar
          v-model="snackError"
          top
          color="red lighten-5 red--text"
          :timeout="4000"
        >
          {{ $t("passwords do not match") }}
          <template v-slot:action="{ attrs }">
            <v-btn icon v-bind="attrs" color="red" @click="snackError = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="snackErrorUpdate"
          top
          color="red lighten-5 red--text"
          :timeout="4000"
        >
          {{ $t("could not update your password. Please again later.") }}
          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              v-bind="attrs"
              color="red"
              @click="snackErrorUpdate = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="snackDenied"
          top
          color="red lighten-5 red--text"
          :timeout="4000"
        >
          <span> {{ $t("account related to that email does not exist") }}</span>
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" icon color="red" @click="snackDenied = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="snackSuccess"
          top
          color="green lighten-4 green--text text--darken-3"
          :timeout="4000"
        >
          <span> {{ $t("password updated successfully") }} </span>

          <template v-slot:action="{ attrs }">
            <v-btn
              v-bind="attrs"
              icon
              color="green darken-3"
              @click="snackSuccess = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="snackOnline"
          top
          color="warning lighten-4 warning--text text--darken-3"
          :timeout="4000"
        >
          <span>
            {{ $t("offline-message") }}
          </span>

          <template v-slot:action="{ attrs }">
            <v-btn
              v-bind="attrs"
              icon
              color="warning darken-3"
              @click="snackOnline = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <Footer />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import axios from '@/plugins/axios'
import { sendEmail } from "@/components/emails/emails";
import Footer from "@/components/layout/loginPageFooter";
import db from "@/plugins/fb";
// import jwt from "jsonwebtoken";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      snackError: false,
      snackSuccess: false,
      snackOnline: false,
      snackDenied: false,
      snackErrorUpdate: false,
      loading: false,
      viewPass: false,
      viewPassConfirm: false,
      password: "",
      user: "",
      userData: "",
      code: "",
      confirmPassword: "",
      resetCode: "",
      menuLang: false,
      languages: [
        {
          flag: "us",
          lang: "en",
        },
        {
          flag: "fr",
          lang: "fr",
        },
      ],
    };
  },
  computed: {
    title() {
      return this.$t("reset password");
    },
    rules() {
      return {
        password: [(v) => !!v || this.$t("password is required")],
        confirmPassword: [
          (v) => !!v || this.$t("confirm password is required"),
        ],
      };
    },
  },
  mounted() {
    localStorage.clear();
    let cleardata = {
      status: false,
      userId: "",
    };

    this.$store.commit("SET_SESSION", cleardata);
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
  },

  methods: {
    sendEmail,
    checkConnection() {
      this.snackOnline = navigator.onLine ? false : true;
    },

    validate() {
      if (navigator.onLine) {
        //this.sendEmail(this.email);
        this.updatePassword();
      } else {
        return this.checkConnection();
      }
    },

    updatePassword() {
      this.loading = true;

      if (this.password == this.confirmPassword) {
        db.collection("users")
          .where("username", "==", this.$store.state.userData.id)
          .get()
          .then((snapshot) => {
            this.userData = null;

            if (snapshot.size > 0) {
              snapshot.forEach((snaps) => {
                this.userData = snaps.data();
                this.userData.id = snaps.id;

                this.userData.password = this.password;

                db.collection("users")
                  .doc(this.userData.id)
                  .update(this.userData)
                  .then(() => {
                    this.loading = false;
                    this.snackSuccess = true;
                    setTimeout(
                      () => this.$router.push({ name: "Login" }),
                      2000
                    );
                  })
                  .catch(() => {
                    this.snackErrorUpdate = true;
                  });
              });
            }
          });
      } else {
        this.loading = false;
        this.snackError = true;
      }
    },

    loginPage() {
      this.$router.push({ name: "Login" });
    },

    switchLanguage(lang) {
      this.$i18n.locale = lang;
      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({ params: { lang: lang } });
      //setTimeout(() => window.location.reload(), 500);
    },
    accessDenied() {
      this.snackError = true;
      this.loading = false;
      this.post.password = null;
    },
  },
};
</script>
<style scoped>
.caption {
  cursor: pointer;
}
</style>
